import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import {parse} from 'query-string'
import {redirectToWechatLogin} from '@xdml/auth-xdml'

if (/MicroMessenger/i.test(navigator.userAgent.toLowerCase()) && (window.location.hash !== '#validation')) {
  const query = parse(window.location.search);
  const originUrl = decodeURIComponent(query.redirect_to || '/');
  redirectToWechatLogin(originUrl)
} else ReactDOM.render(<App />, document.getElementById('root'))
