import React, { Component } from 'react'
import { Login, Bind, Validation } from '@xdml/auth-xdml'
import './index.css';

export default class App extends Component {
  render() {
    const baseUrl = window.location.hostname === 'localhost' ? '//localhost:3001' : "https://user.xiedaimala.com";
    const apiList = {
      smsCodeRequest: '/api/v1/sms_code',
      bindingRequest: '/api/v1/users/binding',
      loginRequest: '/api/v1/session',
      userGet: '/api/v1/session'
    }
    const getPage = () => {
      switch (window.location.hash) {
        case '#phone-bind':
          return <Bind title='写代码啦' baseUrl={baseUrl} api={apiList}
            logoUrl="https://static.xiedaimala.com/xdml/image/6e556a51-b8ff-466f-bda6-7d1847e39f2e/2019-11-19-17-29-16.png" />
        case '#validation':
          return <Validation/>
        default:
          return <Login title='饥人谷'
                        baseUrl={baseUrl}
                        api={apiList}
                        useEmbedQrcode
                        wechatAppId="wx63c70a37cdf2235e"
                        logoUrl="https://static.xiedaimala.com/xdml/image/6e556a51-b8ff-466f-bda6-7d1847e39f2e/2019-11-19-17-29-16.png" />
      }
    }
    return (
      <div className='wrapper'>
        {getPage()}
      </div>
    )
  }
}
